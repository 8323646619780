import { styled } from '@mui/material/styles';
import { QuerySchoolsResponse } from '../../api_calls/response';
import { Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { removeSchool } from '../../api_calls';

interface Props {
    className?: string;
    college: QuerySchoolsResponse;
    openApplicationDialog: (schoolId: string, schoolName: string) => void;
    handleRemoveFromColleges: (schoolId: string) => void;
}

const CollegeCard = (props: Props) => {
    const { className, college, openApplicationDialog, handleRemoveFromColleges } = props;

    const handleAddSchool = () => openApplicationDialog(college.id, college.name);
    const handleRemoveSchool = () => removeSchool({ schoolId: college.id }).then(() => handleRemoveFromColleges(college.id));

    return (
        <Grid container className={className} alignItems='center'>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
                <Typography variant='h6'>{college.name}</Typography>
            </Grid>
            <Grid item xs={2} justifyItems='center'>
                <Typography variant='body1'>{college.state}</Typography>
            </Grid>
            <Grid item xs={3} justifyItems='center'>
                <Typography variant='body1'>{college.admissionRate || 0}%</Typography>
            </Grid>
            <Grid item xs={2} justifyItems='center'>
                <div>
                    <IconButton size='small' onClick={() => college.added ? handleRemoveSchool() : handleAddSchool()}>
                        {college.added ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                </div>
            </Grid>
        </Grid>
    );
};

export default styled(CollegeCard)`
    background-color: rgb(226, 226, 226);
    padding: 10px;
    border-radius: 15px;
    display: flex;

    &:hover {
        transform: scale(1.01);
        transition: transform 0.2s;
    }
`;
