import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { States } from '../../lib';
import { Dispatch, SetStateAction } from 'react';
import { SchoolFilterData } from '../../api_calls/request';

interface Props {
    className?: string;
    strings: any;
    schoolFilters: SchoolFilterData;
    setSchoolFilters: Dispatch<SetStateAction<SchoolFilterData>>;
    defaultSchoolFilter: SchoolFilterData;
}

const CollegeFilters = (props: Props) => {
    const { className, strings, schoolFilters, setSchoolFilters, defaultSchoolFilter } = props;

    return (
        <div className={className}>
            <Grid container>
                <Grid item xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h4'>{strings.title}</Typography>
                    <Button
                        onClick={() => setSchoolFilters(defaultSchoolFilter)}
                        variant='outlined'
                        size='small'
                        disabled={schoolFilters.state.length === 0}
                    >
                        {strings.clearFilters}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>{strings.location}</Typography>
                    <Autocomplete
                        multiple
                        options={Object.keys(States)}
                        getOptionLabel={(option) => (States as any)[option]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                            />
                        )}
                        value={schoolFilters.state}
                        onChange={(_, value) => setSchoolFilters({ ...schoolFilters, state: value })}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default styled(CollegeFilters)`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
`;
