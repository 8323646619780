import { Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { QuerySchoolsResponse } from '../../api_calls/response';
import CollegeCard from './CollegeCard';
import { Dispatch, SetStateAction, useState } from 'react';
import CollegeApplicationDialog from './CollegeApplicationDialog';
import { SchoolFilterData } from '../../api_calls/request';

interface Props {
    className?: string;
    strings: any;
    schools: QuerySchoolsResponse[];
    setSchools: Dispatch<SetStateAction<QuerySchoolsResponse[]>>;
    schoolFilters: SchoolFilterData;
    setSchoolFilters: Dispatch<SetStateAction<SchoolFilterData>>;
}

export interface ApplicationDialog {
    schoolName: string,
    schoolId: string,
    major: string,
    year: number,
}

const defaultApplicationDialog: ApplicationDialog = ({ schoolName: '', schoolId: '', major: '', year: 0 });

const CollegeList = (props: Props) => {
    const { className, strings, schools, setSchools, schoolFilters, setSchoolFilters } = props;

    const [applicationDialog, setApplicationDialog] = useState<ApplicationDialog>(defaultApplicationDialog);

    const handleOpenApplicationDialog = (schoolId: string, schoolName: string) => {
        setApplicationDialog({ ...defaultApplicationDialog, schoolId, schoolName })
    };

    const handleAddToColleges = (schoolId: string) => setSchools((_schools) => {
        const newSchools: QuerySchoolsResponse[] = [];

        for (const school of _schools) {
            const newSchool = { ...school };

            if (school.id === schoolId) {
                newSchool.added = true;
            }

            newSchools.push(newSchool);
        }

        return newSchools;
    });

    const handleRemoveFromColleges = (schoolId: string) => setSchools((_schools) => {
        const newSchools: QuerySchoolsResponse[] = [];

        for (const school of _schools) {
            const newSchool = { ...school };

            if (school.id === schoolId) {
                newSchool.added = false;
            }

            newSchools.push(newSchool);
        }

        return newSchools;
    });

    return (
        <div className={className}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h4'>{strings.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size='small'
                        variant='standard'
                        placeholder={strings.search}
                        value={schoolFilters.name}
                        onChange={(e) => setSchoolFilters({ ...schoolFilters, name: e.target.value })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container style={{ padding: '10px' }} spacing={3}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>
                            <Typography variant='h4'>{strings.name}</Typography>
                        </Grid>
                        <Grid item xs={2} justifyItems='center'>
                            <Typography variant='h4'>{strings.state}</Typography>
                        </Grid>
                        <Grid item xs={3} justifyItems='center'>
                            <Typography variant='h4'>{strings.admissionRate}</Typography>
                        </Grid>
                        <Grid item xs={2} justifyItems='center'>
                            <Typography variant='h4'>{strings.add}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {schools.map((school) => (
                    <Grid item xs={12} key={school.id}>
                        <CollegeCard
                            college={school}
                            openApplicationDialog={handleOpenApplicationDialog}
                            handleRemoveFromColleges={handleRemoveFromColleges}
                        />
                    </Grid>
                ))}
            </Grid>
            <CollegeApplicationDialog
                applicationDialog={applicationDialog}
                setApplicationDialog={setApplicationDialog}
                defaultApplicationDialog={defaultApplicationDialog}
                strings={strings.applicationDialog}
                handleAddToColleges={handleAddToColleges}
            />
        </div>
    );
};

export default styled(CollegeList)`
    background-color: white;
    padding: 10px;
    border-radius: 5px;
`;
