export type JoinWaitlistResponse = void;
export enum Gender {
    'Male' = 'Male',
    'Female' = 'Female',
    'Other' = 'Other',
};
export enum SchoolType {
    'HighSchool' = 'HighSchool',
    'CommunityCollege' = 'CommunityCollege',
    'University' = 'University',
}
export interface FetchAccount {
    email: string,
    firstName: string,
    lastName: string,
    highSchool: string | null,
    gradYear: number | null,
    gender: Gender | null,
    country: string | null,
    parentalIncome: number | null,
    classRank: number | null,
    gpa: number | null,
    weightedGpa: number | null,
    sat: number | null,
    act: number | null,
}
export interface SignUpResponse {
    token: string;
    student: FetchAccount;
}
export type SignInResponse = SignUpResponse;

export interface QuerySchoolsResponse {
    id: string,
    name: string,
    schoolType: SchoolType,
    state: string,
    admissionRate: number,
    added: boolean,
}

export interface JWToken {
    student_id: string;
    exp: number;
}

