import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import {
    BtnBold, BtnBulletList, BtnItalic, BtnLink, BtnNumberedList, BtnRedo, BtnStrikeThrough,
    BtnUnderline, BtnUndo, Editor, EditorProvider, Toolbar
} from 'react-simple-wysiwyg';

interface Props {
    className?: string;
    strings: any;

}

const NewEssay = (props: Props) => {
    const { className, strings } = props;

    const [html, setHtml] = useState('');
    const [title, setTitle] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [applicationId, setApplicationId] = useState('');

    useEffect(() => {
        setWordCount(getWordCount(html));
    }, [html])

    return (
        <div className={className}>
            <Grid container marginBottom='10px' alignItems='center' justifyContent='center' spacing={3}>
                <Grid item xs={5} alignItems='center'>
                    <TextField
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        size='small'
                        fullWidth
                        placeholder={strings.title}
                        label={strings.title}
                        variant='standard'
                    />
                </Grid>
                <Grid item xs={4} display='flex' alignItems='flex-end' justifyContent='end'>
                    <FormControl fullWidth>
                        <InputLabel>{strings.applicationId}</InputLabel>
                        <Select
                            value={applicationId}
                            label={strings.applicationId}
                            onChange={(e) => setApplicationId(e.target.value)}
                            variant='standard'
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container item xs={2} display='flex' alignItems='flex-end' justifyContent='end' spacing={1}>
                    <Grid item>
                        <Typography variant='h5'>
                            {strings.wordCount}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='body1'>
                            {wordCount}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={1} display='flex' justifyContent='flex-end'>
                    <Button variant='contained'>
                        {strings.save}
                    </Button>
                </Grid>
            </Grid>
            <EditorProvider>
                <Editor
                    placeholder={strings.placeHolder}
                    value={html}
                    onChange={(e: any) => setHtml(e.target.value)}
                    containerProps={{ style: { height: '85%', background: 'white' } }}
                >
                    <Toolbar>
                        <BtnUndo />
                        <BtnRedo />
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <BtnStrikeThrough />
                        <BtnLink />
                    </Toolbar>
                </Editor>
            </EditorProvider>
        </div>
    );
};

export default styled(NewEssay)`
    border-radius: 5px;
    padding: 10px;
    height: 95vh;

    .rsw-toolbar {
        display: flex;
        justify-content: center;
        size: 30px;
    }

    .rsw-toolbar .rsw-btn {
        font-size: 16px;
        padding: 10px;
        width: 40px; 
        height: 40px;
    }
`;

const getWordCount = (text: string): number => {
    // Create HTML element
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;

    // Remove HTML tags
    const decodedText = textArea.value.replace(/<[^>]*>/g, ' ');

    // Normalize whitespace: replace multiple whitespace characters with a single space
    const normalizedText = decodedText.replace(/\s+/g, ' ').trim();

    // Split the text by spaces to get an array of words
    const words = normalizedText.split(' ');

    // Filter out any empty strings resulting from the split operation
    return (words.filter(word => word.length > 0).length);
}